export function UpdateMainContentMargin () {
  // Ge the nav bar, there should be only one
  var navbar = document.getElementsByTagName("nav")[0]

  // Ge the main page content, there should be only one
  var pageContent = document.getElementsByClassName("main-content")[0]

  // Get the height of the navbar
  var rect = navbar.getBoundingClientRect();
  var totalHeight = rect.height;

  // Set the margin of the main content as the height of the navbar
  pageContent.style.marginTop=`${totalHeight}px`;
}
