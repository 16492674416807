import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import EditIcon from './images/path1.svg';
import DelIcon from './images/path2.svg';

import { monthlyEnergyPerItem, monthlyCostPerItem } from './energyPerItem.js';

export default function ItemList ({listItems, setlistItems, onItemDelete, energyCost, editItem }) {

  function removeItem (id) {
    var tempList = [...listItems.slice(0, id), ...listItems.slice(id + 1)];
    setlistItems(tempList);
    onItemDelete([...tempList].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  }

  return(
    <div>
      {listItems && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Appliance</th>
              <th scope="col">kWh per month</th>
              <th scope="col">$USD per month</th>
            </tr>
          </thead>
          <tbody>
            {listItems.map(item => (
              item.name != null ?
                <tr key={Math.random()}>
                  <td>{item.name}</td>
                  <td>{monthlyEnergyPerItem(item)}</td>
                  <td>{monthlyCostPerItem(item, energyCost)}</td>
                  <td>
                    <img 
                      className="btn-primary btn p-1" 
                      onClick={() => editItem(listItems.indexOf(item))} 
                      src={EditIcon}
                      alt="Edit Button"
                    />
                  </td>
                  <td>
                    <img 
                      className="btn-danger btn p-1" 
                      onClick={() => removeItem(listItems.indexOf(item))}
                      src={DelIcon}
                      alt="Delete Button"
                    />
                  </td>

                </tr>
                : null
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
