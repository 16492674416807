import './PowerCal.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

import './Navbar.css';
import Navbar from './Navbar.js';
import TotalEnergy from './TotalEnergy.js';
import ItemForm from './ItemForm.js';
import ItemList from './ItemList.js';
import { monthlyCostPerItem } from './energyPerItem.js';
import EnergyCostForm from './EnergyCostForm.js';
import ModalEditItem from './EditItem.js';

import Logo from './images/hippowcalc logo.svg';

import Footer from './Footer.js';

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PowerCal () {
  const [listItems, setlistItems] = useState([Array(4).fill(null)]);
  const [energyCost, setEnergyCost] = useState(0.14);
  const [data] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // opens the modal
  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // closes the modal
  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
    updateChartData([...listItems].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Dataset 1',
        data,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  const [chartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cost of energy per appliance',
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: '$USD'
        }
      }
    }
  });

  const updateChartData = (newItemList) => {
    const newData = {
      labels: newItemList.map(item => item != null ? item.name : null),
      datasets: [
        {
          data: newItemList.map(item => item != null ? monthlyCostPerItem(item, energyCost) : null),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    setChartData(newData);
  }

  const handleEnergyCostFormSubmit = (newEnergyCost) => {
    updateChartData([...listItems].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  };

  const handleFormSubmit = (formData) => {
    setlistItems([...listItems.map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null), formData]);
    updateChartData([...listItems, formData].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  };

  const updateEnergyCost = (newEnergyCost) => {
    setEnergyCost(newEnergyCost);
    handleEnergyCostFormSubmit(newEnergyCost)
  }

  return(
    <>
      <Navbar />
      <div id="powercal" 
        className="main-content row justify-content-center powercal mb-5">
        <div className="col col-12 mt-5  hippowcalc-logo justify-content-center d-flex">
          <img alt="Hippowcalc" src={Logo} />
        </div>
        <div className="col col-12 text-center ">
          <h1 className="title-font">HIPPOWCALC</h1>
          <p className="content-font">A power consumtion calculator</p>
        </div>
        <div className="col col-12 col-md-6 px-1 mb-md-5 px-md-5">
          <div className="content power-form px-3 mb-3">
            <p className="text-center pt-2 text-muted">Please enter your home appliances details</p>
            <ItemForm 
              onFormSubmit={handleFormSubmit}
            />
            <TotalEnergy listItems={listItems} energyCost={energyCost}/>
            <div className="overflow-scroll">
              <ItemList 
                listItems={listItems}
                setlistItems={setlistItems} 
                onItemDelete={updateChartData}
                energyCost={energyCost}
                editItem={openModal}
              />
            </div>
          </div>
        </div>

        <div className="col col-12 col-md-6 px-1 mb-md-5 px-md-5">
          <div className="content graph px-3">
            <div className="row justify-content-center">

              <div className="col col-12 col-md-6 text-center">
                <EnergyCostForm 
                  updateEnergyCost={updateEnergyCost}
                />
              </div>
            </div>
            <Bar options={chartOptions} data={chartData} />
          </div>
        </div>
        <ModalEditItem 
          listItems={listItems} 
          setlistItems={setlistItems} 
          selectedItem={selectedItem}
          isModalOpen={isModalOpen}
          closeModal={closeModal} />
      </div>
      <Footer />
    </>
  );
}
