import React, { useState } from 'react';
import { formValidation } from './FormValidation.js';

export default function ModalEditItem ({listItems, setlistItems, selectedItem, isModalOpen, closeModal}) {
  const [errors, setErrors] = useState ({});
  const [isFormValid, setIsFormValid] = useState (true);
  const handleModelFormChange = (e, id) => {
    const { name, value } = e.target;

    var tmpItem = listItems[id];
    tmpItem[name] = value;
    var tempErrors = formValidation(tmpItem, setErrors);
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0){
      setIsFormValid(true);
     setlistItems((prevListItems) =>
        prevListItems.map((cnt) =>
          listItems.indexOf(cnt) === id
            ? { ...cnt, [name]: value }
            : cnt
        )
      );
    }else{
      setIsFormValid(false);
    }
  };

  const handleClosing = () => {
    if (isFormValid){
      closeModal();
    }
}

  return (
    <>
      {isModalOpen && selectedItem !== null && (
        <div className="modal-overlay" onClick={handleClosing}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <form>
              <div className="row">
                <div className="col-12 mt-1">
                  <label>Name</label>
                  <input type="text" 
                    name="name" 
                    className="form-control" 
                    placeholder="Enter appliance name" 
                    value={listItems[selectedItem].name}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />
                  {errors.name && <span className="text-danger">{errors.name}</span>}
                </div>

                <div className="col-12 mt-1">
                  <label>Units</label>
                  <input type="text" 
                    name="units" 
                    className="form-control" 
                    placeholder="Enter number of units" 
                    value={listItems[selectedItem].units}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />
                  {errors.units && <span className="text-danger">{errors.units}</span>}
                </div>

                <div className="col-12 mt-1">
                  <label>Wattage</label>
                  <input type="text" 
                    name="wattage" 
                    className="form-control" 
                    placeholder="Enter appliance wattage" 
                    value={listItems[selectedItem].wattage}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />        
                  {errors.wattage && <span className="text-danger">{errors.wattage}</span>}
                </div>
                <div className="col-12 mt-1">
                  <label>Intensity (0% to 100%)</label>
                  <input type="text" 
                    name="intensity" 
                    className="form-control" 
                    placeholder="Enter intensity" 
                    value={listItems[selectedItem].intensity}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />        
                  {errors.intensity && <span className="text-danger">{errors.intensity}</span>}
                </div>
                <div className="col-12 mt-1">
                  <label>Hours of usage per day</label>
                  <input type="text" 
                    name="usage" 
                    className="form-control" 
                    placeholder="Enter amount of hours" 
                    value={listItems[selectedItem].usage}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />        
                  {errors.usage && <span className="text-danger">{errors.usage}</span>}
                </div>
                <div className="col-12 mt-1">
                  <label>Days of usage per month</label>
                  <input type="text" 
                    name="days" 
                    className="form-control" 
                    placeholder="Enter amount of days" 
                    value={listItems[selectedItem].days}
                    onChange={(e) => handleModelFormChange(e,selectedItem)} />        
                  {errors.days && <span className="text-danger">{errors.days}</span>}
                </div>
              </div>
            </form>
            <button onClick={handleClosing}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}
