import './Footer.css';

import MyLogo from './images/My Logo.svg';

import SocialX from './images/twitter-x.svg';
import SocialInstagram from './images/instagram.svg';
import SocialLinkedin from './images/linkedin.svg';

import Browser from './images/browser-chrome.svg';
import Person from './images/person-circle.svg';

import LocIcon from './images/geo-alt-fill.svg';
// import EmailIcon from './images/envelope-fill.svg';

function Footer() {
  return (
    <div id="footer" className="row content-font justify-contenct-center py-3 mx-0">
      <div className="col col-12 col-md-6 footer-logo">
        <img src={MyLogo} alt="Me" />

      </div>
      <div className="col col-12 col-md-6 footer-details">
        <div className="text-center text-md-start mb-2 footer-channels">
          <p><img src={Person} alt="Creator" /> Created by Alejandro Núñez</p>
          <p><img src={Browser} alt="Address" /> <a href="https://alejandro-nunez.com">Visit My Portfolio</a></p>
          <p><img src={LocIcon} alt="Address" /> Normandie, France</p>
        </div>

        <div className="row">
          <div className="col col-12 text-center text-md-start">
            <p className="mb-0">Follow  me in my Social Media</p>
          </div>
          <div className="col col-12 link-socials d-flex justify-content-md-start justify-content-center pt-0">
            <a href="https://twitter.com/anunezdev" >
              <img src={SocialX} alt="X logo link" />
            </a>
            <a href="https://www.instagram.com/alejandro.nunez.fullstack/" > 
              <img src={SocialInstagram} alt="Instagram logo link" />
            </a>
            <a href="https://www.linkedin.com/in/nunez-alejandro/" >
              <img src={SocialLinkedin} alt="Linkedin logo link" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
