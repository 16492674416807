export function formValidation (formData, setErrors) {
    let tempErrors = {};
    if(!formData.name.trim()) {
      tempErrors.name = 'Field is required';
    }else if(!/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(formData.name.trim())) {
      tempErrors.name = 'Only alphabet letters A-Z';
    }

    if(!formData.units.trim()) {
      tempErrors.units = 'Field is required';
    }else if(!isInteger(formData.units.trim())) {
      tempErrors.units = 'Only positive numbers intergers';
    }

    if(!formData.wattage.trim()) {
      tempErrors.wattage = 'Field is required';
    }else if(!isFloat(formData.wattage.trim())){ 
      tempErrors.wattage = 'Only positive numbers';
    }

    if(!formData.intensity.trim()) {
      tempErrors.intensity = 'Field is required';
    }else if(!isFloat(formData.intensity.trim())){ 
      tempErrors.intensity = 'Only positive numbers';
    }else if(parseFloat(formData.intensity) < 0 || 
      parseFloat(formData.intensity) > 100 ){
      tempErrors.intensity = 'Value must be from 0 to 100';
    }

    if(!formData.usage.trim()) {
      tempErrors.usage = 'Field is required';
    }else if(!isFloat(formData.usage.trim())){ 
      tempErrors.usage = 'Only positive numbers';
    }else if(parseFloat(formData.usage) < 0 || 
      parseFloat(formData.usage) > 24 ){
      tempErrors.usage = 'Value must be from 0 to 24';
    }

    if(!formData.days.trim()) {
      tempErrors.days = 'Field is required';
    }else if(!isFloat(formData.days.trim())){ 
      tempErrors.days = 'Only positive numbers';
    }else if(parseFloat(formData.days) < 0 || 
      parseFloat(formData.days) > 31 ){
      tempErrors.days = 'Value must be from 0 to 31';
    }

    return tempErrors;
}

function isInteger(str) {
  // Regular expression to match an integer number
  const intRegex = /^\d+$/;
  return intRegex.test(str);
}

  function isFloat(str) {
  // Regular expression to match a float number
  const floatRegex = /^\d*(\.\d+)?$/;
  return floatRegex.test(str);
}
