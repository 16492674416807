import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { dailyEnergyPerItem, monthlyEnergyPerItem } from './energyPerItem.js';

export default function TotalEnergy ({listItems, energyCost}) {
  var totalDailykWh = 0.0;
  var totalMonthCost = 0.0;
  var totalMonthlykWh = 0.0;
  listItems.map(item => ( item.name != null ? totalDailykWh = totalDailykWh + dailyEnergyPerItem(item) : null));
  listItems.map(item => ( item.name != null ? totalMonthlykWh = totalMonthlykWh + monthlyEnergyPerItem(item) : null));

  totalDailykWh = Math.round(totalDailykWh*1000)/1000
  totalMonthlykWh = Math.round(totalMonthlykWh*1000)/1000
  totalMonthCost = Math.round(totalMonthlykWh*1000*energyCost)/1000
  return (
      <table className="table mt-3">
        <thead>
          <tr>
            <th scope="col">Total Monthly kWh</th>
            <th scope="col">Total Monthly $USD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalMonthlykWh}</td>
            <td>{totalMonthCost}</td>
          </tr>
        </tbody>
      </table>
  );
}
