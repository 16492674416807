import './Navbar.css';
import { useEffect } from 'react'
import { UpdateMainContentMargin } from './UpdateMainContentMargin.js';


function closeNavbar() {
  var navbarCollapse = document.querySelector('.navbar-collapse');
  if (navbarCollapse.classList.contains('show')) {
    console.log("trying to collapse")
    navbarCollapse.classList.remove('show');
  }
}

function NavItem ({itemName, itemUrl}) {
  return <li className="nav-item">
    <a className="nav-link" href={itemUrl} onClick={closeNavbar}>{itemName}</a>
  </li>
}
export default function Navbar () {
  useEffect(() => {
    const handleResize = () => {
      UpdateMainContentMargin();
    };

    // Trigger the function on initial render
    handleResize();

    // Add the event listener for subsequent resizes
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-toggleable-sm 
        navbar-light">
        <div className="container-fluid">
          <button 
            className="navbar-toggler ms-1 my-1" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target=".navbar-collapse" 
            aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">

            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse 
            collapse d-sm-inline-flex justify-content-between">
            <ul className="navbar-nav flex-grow-1 p-2">
              <NavItem
                itemName="Home" 
                itemUrl="/" />
              <NavItem
                itemName="About Me" 
                itemUrl="/About" />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
